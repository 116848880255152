import React, { FC } from 'react';
import { Link } from 'react-scroll';

import DangerouslySetInnerHtml from 'gatsby-theme-gaviscon/src/components/common/DangerouslySetInnerHtml';
import Button from 'gatsby-theme-gaviscon/src/components/common/Button';
import IconCustom from 'gatsby-theme-gaviscon/src/components/common/IconCustom';
import GatsbyImage from 'gatsby-theme-gaviscon/src/components/common/GatsbyImage';

import useScreenRecognition from 'gatsby-theme-gaviscon/src/hooks/useScreenRecognition';
import { IBannerProps } from 'gatsby-theme-gaviscon/src/components/HomeBanner/model';
import 'gatsby-theme-gaviscon/src/components/HomeBanner/HomeBanner.scss';
import './HomeBannerOverride.scss';

const HomeBanner: FC<IBannerProps> = ({ topBanner }) => {
  const {
    title,
    description,
    homepageBannerDisclaimer,
    image,
    imageAlt,
    ribbonImage,
    ribbonImageAlt,
    productImage,
    productImageAlt,
    link: cta,
    linkVariant,
    ariaScroll,
  } = topBanner[0].properties;

  const { isNotMobile, isMobile } = useScreenRecognition();
  const link = cta?.length ? cta[0] : null;
  const variant = linkVariant?.[0] || 'primary';

  return (
    <div className="home-banner" data-testid="home-banner">
      <div className="home-banner__crop">
        <div className="home-banner__text">
          <h1
            className="home-banner__text-title"
            data-testid="home-banner__title"
          >
            {title.map(({ value }) => (
              <DangerouslySetInnerHtml
                key={value}
                element="span"
                className="home-banner__text-line"
                html={value}
              />
            ))}
          </h1>
          {description ? (
            <DangerouslySetInnerHtml className="home-banner__text-description" html={description} />
          ) : null}
          {link ? (
            <Button to={link.url} variant={variant}>
              {link.name}
              <IconCustom icon="chevron-right" />
            </Button>
          ) : null}
          {homepageBannerDisclaimer ? (
            <p className="home-banner__text-disclaimer">{homepageBannerDisclaimer}</p>
          ) : null}
        </div>
        {isNotMobile && image?.fallbackUrl ? (
          <div className="home-banner__image-desktop">
            <GatsbyImage
              isLazyLoading={false}
              className="home-banner__image-img"
              fluid={image}
              alt={imageAlt}
              objectPosition="50% 100%"
              useFocalPoint
            />
          </div>
        ) : null}
        {isNotMobile && ribbonImage?.fallbackUrl ? (
          <div className="home-banner__ribbon">
            <GatsbyImage
              style={{ position: 'absolute' }}
              isLazyLoading={false}
              objectFit="contain"
              objectPosition="50% 100%"
              fluid={ribbonImage}
              alt={ribbonImageAlt}
            />
          </div>
        ) : null}
        {productImage?.fallbackUrl ? (
          <div className="home-banner__product">
            {isNotMobile ? (
              <GatsbyImage
                isLazyLoading={false}
                objectFit="contain"
                objectPosition="50% 100%"
                className="home-banner__image-img"
                fluid={productImage}
                alt={productImageAlt}
              />
            ) : null}
            {isMobile ? (
              <GatsbyImage
                isLazyLoading={false}
                objectFit="contain"
                objectPosition="50% 100%"
                className="home-banner__image-img"
                fluid={productImage}
                alt={productImageAlt}
                fadeIn={false}
              />
            ) : null}
          </div>
        ) : null}
      </div>
      <div className="home-banner__sticky">
        <div className="home-banner__sticky-link">
          <Link to="main-content" offset={-50} smooth duration={300} name={ariaScroll} tabIndex="0">
            <IconCustom icon="arrow_down" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
